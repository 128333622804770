import axios from 'axios';
import { createAction } from '../lib/redux/actions';
import { setCookie, getCookie } from '../lib/redux/cookie';
import { getIp } from '../lib/redux/request';
import config from '../lib/config';
import isSupportedLanguage from '../lib/isSupportedLanguage';
import processException from '../lib/processException';
import globals from '../globals';

// Action types
export const USER_UPDATES = 'USER_UPDATES';
export const changeUserSettings = createAction(USER_UPDATES, 'updates');
const DOHOP_COOKIE_NAME = 'docookie';

// while part of the site is running on web_dohop, we must store these the same we as web_dohop
// does, otherwise they would be lost between different pages on dohop. After everything is
// moved to web_node, we can store these any way we want.
function encodeCookie(user) {
  return `${user.get('residency')}${user.get('language')}${user.get('currency')}`;
}

function decodeCookie(s) {
  let matches = s && s.match(/^([A-Z]{2})([a-z]{2,3})([A-Z]{3})/);
  if (!matches) return undefined;
  let [residency, language, currency] = matches.slice(1);
  return { language, residency, currency };
}

export function setUserSettings({ language, residency, currency }) {
  return (dispatch, getState) => {
    const global = globals();
    const user = getState().get('user');
    const lang = user.get('language') || 'en';
    const updates = {};
    const isSupportedLang = isSupportedLanguage(language);

    if (language && global.hasIn(['languages', language]) && isSupportedLang) {
      updates.language = language;
    }

    if (residency && global.hasIn(['countries', lang, residency])) {
      updates.residency = residency;
    }

    if (currency && global.hasIn(['currencies', lang, currency])) {
      updates.currency = currency;
    }

    // save to cookie
    const value = encodeCookie(user.merge(updates));
    dispatch(setCookie(DOHOP_COOKIE_NAME, value, { path: '/', maxAge: 60 * 60 * 24 * 365 }));

    if (
      process.env.IS_BROWSER &&
      updates.language &&
      updates.language !== getState().getIn(['user', 'language'])
    ) {
      // we don't want to save to store and re-render client-side if language is changed because we
      // don't have all the data we need to render the site in another language without reloading.
      location.reload();
    } else {
      // dispatch changes when server-side or not changing language client-side
      dispatch(changeUserSettings(updates));
    }
  };
}

/**
 * Fetches default user settings which are currency, residency and language
 * If settings come from the query string, then we use those queries first
 * If settings are stored in cookie then use those settings second
 * @param {Object} queryUserSettings User settings object
 * @param {String} queryUserSettings.language language code
 * @param {String} queryUserSettings.currency currency code
 * @param {String} queryUserSettings.residency residency code
 */
export function loadSettings(queryUserSettings) {
  const { WEB_API } = config;

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const cookie = decodeCookie(dispatch(getCookie(DOHOP_COOKIE_NAME)));

      if (cookie) {
        // We will override cookie settings with properites from queryUserSettings
        dispatch(setUserSettings({ ...cookie, ...queryUserSettings }));
        return resolve();
      }

      // Get user ip address
      const ip = dispatch(getIp());

      try {
        const options = {
          params: { ip },
        };

        const baseUrl = process.env.IS_BROWSER ? '/api' : WEB_API;
        // Get default user settings currency, residency and language
        const res = await axios.get(`${baseUrl}/flights/default-settings/`, options);
        const settings = res.data;

        // We will override settings with properites from queryUserSettings
        dispatch(setUserSettings({ ...settings, ...queryUserSettings }));
        return resolve();
      } catch (error) {
        processException(error);
        return reject();
      }
    });
  };
}
